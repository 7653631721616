<template>
  <Layout>
    <form @submit.prevent="submit">
      <div class="section-content max-w-md mx-auto">
        <div class="form">
          <div class="form-heading text-center mb-6">
            <h5>Faça login em sua inscrição</h5>
          </div>

          <div class="body">
            <div
              :class="{ 'group-horizontal': true, groupError: $v.email.$error }"
            >
              <label class="label"> E-mail </label>

              <input
                type="text"
                placeholder="Informe seu e-mail"
                v-model.trim="$v.email.$model"
              />
            </div>

            <div
              class="text-red-700 text-sm py-2 text-right"
              v-if="$v.email.$error && !$v.email.required"
            >
              E-mail deve ser preenchido.
            </div>

            <div
              :class="{
                'group-horizontal': true,
                groupError: $v.password.$error,
              }"
            >
              <label class="label"> Senha </label>

              <input
                type="password"
                placeholder="Informe sua senha"
                v-model.trim="$v.password.$model"
              />
            </div>

            <div
              class="text-red-700 text-sm py-2 text-right"
              v-if="$v.password.$error && !$v.password.required"
            >
              Senha deve ser preenchida.
            </div>
          </div>
        </div>

        <div class="form-footer">
          <Button
            color="white"
            background="gray-700"
            :group="true"
            @action="toRecovery"
          >
            Recuperar senha
          </Button>

          <Button
            type="submit"
            color="white"
            background="lime-600"
            :group="true"
          >
            Acessar
          </Button>
        </div>
      </div>
    </form>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";

export default Vue.extend({
  name: "SubscriptionLogin",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  components: {
    Layout,
    Button,
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    ...mapActions("subscription", ["login", "logout"]),
    toRecovery() {
      this.$router.push({ name: "SubscriptionRecovery" });
    },
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.changeLoading(true);

        let loginData = {
          email: this.email,
          password: this.password,
        };

        const loginIn = await this.login(loginData);

        if (loginIn.verified) {
          this.changeLoading(false);

          this.sendNotification({
            type: "success",
            message: loginIn.message,
          });

          this.$router.push({ name: "SubscriptionSituation" });
        } else {
          this.changeLoading(false);

          this.sendNotification({
            type: "error",
            message: loginIn.message,
          });
        }
      }
    },
  },
  mounted() {
    this.logout();
  },
});
</script>
